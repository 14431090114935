// Generated code; DO NOT EDIT

const buildinfo = {
    version: '0.1.0',
    gitcommit: '45a091d',
    buildDate: '2024-09-13T19:03:32.806Z',
};

console.log('version: 0.1.0 git: 45a091d date: 2024-09-13T19:03:32.806Z');

export default buildinfo;
